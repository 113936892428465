export const QstMrk = 
<svg fill="C7C7C7" 
xmlns="http://www.w3.org/2000/svg" 
className="fill-[#838592] hover:fill-[#7190eb]"
viewBox="0 0 21 20">
    <path d="M13.74 9.6c-.463.632-.947.968-1.305 1.221-.4.274-.568.4-.61 1.053a.55.55 0 01-.548.505h-.863a.576.576 0 01-.568-.61c.126-1.516.715-2.064 1.452-2.569.295-.21.569-.4.842-.779a1.29 1.29 0 00.085-1.347c-.148-.316-.569-.821-1.537-.821-1.263 0-1.642.8-1.769 1.41a.545.545 0 01-.547.463h-.884c-.337 0-.61-.294-.548-.631.232-1.663 1.432-3.242 3.748-3.242 1.452 0 2.694.715 3.305 1.915.59 1.137.484 2.443-.253 3.432zm-2.905 6.126a1.2 1.2 0 11-.001-2.399 1.2 1.2 0 01.001 2.4zM10.667 0c-5.516 0-10 4.484-10 10s4.484 10 10 10c5.515 0 10-4.484 10-10s-4.485-10-10-10z">
        </path>
</svg>


export const NightMode = 
<svg 
xmlns="http://www.w3.org/2000/svg" 
className="fill-[#838592] hover:fill-[#7190eb]"
viewBox="0 0 20 20" 
id="SETTINGS_NIGHT_MODE">
    <path d="M9.817 4.9A4.924 4.924 0 004.9 9.818a4.924 4.924 0 004.917 4.918 4.924 4.924 0 004.918-4.918A4.924 4.924 0 009.817 4.9zm0-1.446a.96.96 0 01-.96-.96V.96a.96.96 0 111.92 0v1.535c0 .53-.43.959-.96.959zm0 12.726a.96.96 0 00-.96.96v1.535a.96.96 0 101.92 0V17.14a.96.96 0 00-.96-.96zm4.5-10.862a.96.96 0 010-1.357l1.085-1.086a.96.96 0 011.357 1.357l-1.086 1.086a.96.96 0 01-1.356 0zm-9 9a.959.959 0 00-1.356 0l-1.086 1.085a.96.96 0 001.357 1.357l1.086-1.087a.958.958 0 000-1.356zm10.863-4.5c0-.53.43-.96.96-.96h1.535a.96.96 0 110 1.919H17.14a.96.96 0 01-.96-.96zm-12.726 0a.96.96 0 00-.96-.96H.96a.96.96 0 100 1.919h1.535c.53 0 .959-.43.959-.96zm10.863 4.5a.96.96 0 011.356 0l1.086 1.085a.959.959 0 11-1.357 1.357l-1.085-1.086a.959.959 0 010-1.357zm-9-9a.96.96 0 000-1.357L4.233 2.876a.96.96 0 00-1.357 1.356l1.086 1.086a.959.959 0 001.357 0z" fill-rule="nonzero">
        </path>
    </svg>

    export const DayMode = 
    <svg 
    xmlns="http://www.w3.org/2000/svg"
    className="fill-[#838592] hover:fill-[#7190eb]"
    viewBox="0 0 23 24" id="SETTINGS_NIGHT_MODE">
        <path d="M16.481 17.752C11.318 16.842 7.88 11.871 8.8 6.65a9.63 9.63 0 011.765-4.085c-3.968.567-7.31 3.647-8.052 7.85-.92 5.221 2.519 10.192 7.682 11.103 3.645.642 7.166-.915 9.252-3.72a9.376 9.376 0 01-2.965-.046z" fill-rule="nonzero">
            </path>
    </svg>

    export const Home = 
    <svg 
    viewBox="0 0 24 18"
    className="w-[25px] h-[25px] fill-[#d5d9f2]"
    xmlns="http://www.w3.org/2000/svg">
        <path d="M11.571 4.286l-8.128 6.348a.173.173 0 0 1-.007.04.174.174 0 0 0-.007.04v6.428c0 .233.09.434.268.603.18.17.391.255.636.255h5.429v-5.143h3.619V18h5.428a.893.893 0 0 0 .636-.255c.18-.17.27-.37.27-.603v-6.428a.18.18 0 0 0-.015-.08l-8.129-6.348z">
        </path>
        <path d="M22.983 9.223L19.84 6.537V.517a.466.466 0 0 0-.13-.34.44.44 0 0 0-.33-.133h-2.756a.441.441 0 0 0-.33.133.467.467 0 0 0-.129.34v2.877L12.663.384A1.646 1.646 0 0 0 11.572 0c-.421 0-.785.128-1.091.384L.16 9.223a.433.433 0 0 0-.158.317.487.487 0 0 0 .1.347l.89 1.092a.486.486 0 0 0 .301.162c.115.01.23-.024.345-.103l9.933-8.515 9.934 8.515a.435.435 0 0 0 .301.103h.044a.486.486 0 0 0 .301-.162l.89-1.092a.487.487 0 0 0 .1-.347.434.434 0 0 0-.158-.317z"></path>
        </svg>


export const Eye = <svg aria-hidden="true" 
focusable="false"
className="w-[20px] h-[20px] text-gray-500 hover:text-[#5370c2]"
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 576 512">
    <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path></svg>


export const DropDwn = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[18px] h-[18px] text-gray-500 hover:text-[#5370c2]"
        viewBox="0 0 16 10"
    >
        <path d="M8.07 8.14l-.7.7.7.72.7-.71-.7-.7zM14.44.36L7.36 7.43l1.42 1.42 7.07-7.07L14.44.36zM8.78 7.43L1.7.36.29 1.78l7.07 7.07 1.42-1.42z"></path>
    </svg>
);

export const Eye2 = <svg aria-hidden="true" 
focusable="false"
className="w-[20px] h-[20px] text-red-500"
role="img" 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 576 512">
    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path></svg>


export const EyeSlash = <svg aria-hidden="true" 
focusable="false"
className="w-[20px] h-[20px]"
role="img" 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 640 512">
    <path fill="currentColor" d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path></svg>