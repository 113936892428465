import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/Themecontext';

export default function Home() {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const walletMessages = [
    "This wallet makes it easy to access your crypto and interact with blockchain. Guarda does not have access to your funds.",
    "Restore your existing Guarda Wallet, import private key from any other one to create a new Wallet right now!"
  ];

  return (
    <div className='flex flex-col w-full max-w-full sm:max-w-[550px] mx-auto'>
      <div className={`flex flex-col px-[20px] md:px-[40px] justify-center items-center gap-[15px] bg-[#303132] mx-auto`}>
        <h1 className='text-[18px] md:text-[21px] mt-[20px] md:mt-[30px] font-[700] mb-[30px] md:mb-[40px] text-center text-[#7190eb]'>
          Hello! Welcome to Guarda Wallet
        </h1>
        <div className='flex flex-col gap-[20px] w-full'>
          <div className='flex flex-col gap-[15px]'>
            {walletMessages.map((message, index) => (
              <h1 key={index} className='text-[#fffc] text-[13px] md:text-[14px]'>
                {message}
              </h1>
            ))}
          </div>
          <button
            className='w-full mt-[20px] bg-[#7190eb] hover:bg-[#6b87d9] px-[20px] py-[10px] rounded-[3px] text-white'
            onClick={() => navigate('/create')}
          >
            Create a new wallet
          </button>
          <button
            className='text-[#3365f2] px-[20px] py-[10px] rounded-[3px] w-full border border-transparent hover:border-blue-500 mb-[40px] md:mb-[60px]'
            onClick={() => navigate('/restore')}
          >
            Restore or Import
          </button>
        </div>
      </div>
      <div className='w-full bg-[#111111] flex flex-col items-start gap-[10px] px-[20px] md:px-[40px] py-[15px] md:py-[20px]'>
        <ul className='flex flex-col gap-[5px] items-start cursor-pointer'>
          <li className='text-[#878787] text-[13px] md:text-[14px] hover:underline'>What is exactly Guarda Wallet?</li>
          <li className='text-[#878787] text-[13px] md:text-[14px] hover:underline'>How to import another wallet here?</li>
        </ul>
      </div>
    </div>
  );
}
