import React from 'react';
import { Home } from '../Svg';

export default function Footer() {
    const currentYear = new Date().getFullYear();

    const links = [
        { text: "Back to Guarda.com", icon: Home, url: "" },
        { text: "Support", url: "https://guarda.com/" },
        { text: "Coin Status", url: "https://guarda.com/coins/" },
        { text: "Privacy Policy", url: "https://guarda.com/privacy-policy" },
        { text: "Terms of Service", url: "https://guarda.com/terms-of-service" }
    ];

    const images = [
        '/asset/app_store.png',
        '/asset/play_store.png',
        '/asset/visa.svg',
    ];

    return (
        <div className='flex flex-col bg-[#252526] items-center w-full px-[20px] md:px-[70px] py-[20px] md:py-[30px]'>

            {/* Links Section */}
            <div className='flex flex-col md:flex-row w-full justify-between items-center gap-[15px] md:gap-[40px]'>
                <div className='flex flex-col md:flex-row gap-[10px] items-center md:items-start'>
                    {links.map((link, index) => (
                        <a key={index} href={link.url} className='text-[#ffffff] flex items-center hover:underline'>
                            {link.icon &&
                                <div className='w-[16px] h-[16px] flex items-center mr-[5px]'>
                                    {Home}
                                </div>}
                            <span className='text-[13px]'>{link.text}</span>
                        </a>
                    ))}
                </div>

                {/* Images Section - Hidden on mobile */}
                <div className='hidden md:flex gap-[10px]'>
                    {images.map((src, index) => (
                        <img
                            key={index}
                            src={src}
                            alt={`Image ${index + 1}`}
                            className={`w-[100px] ${index === 1 ? "mr-[80px]" : ""}`}
                        />
                    ))}
                </div>
            </div>

            {/* Divider Line - Only visible on desktop */}
            <div className='hidden md:flex mt-4 w-full justify-center'>
                <div className='border-b border-[#1e1e1e] w-full' />
            </div>

            {/* Additional Text - Only visible on desktop */}
            <div className='hidden md:flex flex-col gap-[10px] justify-start w-full text-left text-[#aaa9a9] mt-[15px]'>
                <span className='text-[13px]'>
                    © {currentYear} Guarda. All rights reserved
                </span>
                <span className='text-[13px]'>
                    GUARDACO LDA, provides virtual currency wallet service and services related to it, Legal entity ID - 516458965, whose registered office is Portugal, 1050-134 Lisboa, Rua Latino Coelho, str. 87.
                </span>
                <span className='text-[12px]'>
                    GUARDARIAN OÜ, a private limited company incorporated in Estonia with company number 14320990, whose registered office address is Rotermanni tn 2, Tallinn 10111, Estonia (“Guardarian”). Guardarian has obtained the license to provide virtual currency services which is issued by the respective Estonian authority. License’s number is FVT000200. Guardarian provides the virtual currency against virtual or fiat currency exchange service and card related products. Prepaid Card VISA is issued by Walletto UAB.
                </span>
            </div>
        </div>
    );
}
