import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Err from './Err';

export default function Create() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [error, setError] = useState('');
    const [password, setPassword] = useState(''); 
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleButtonClick = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setShowErrorModal(true);
            setError('Example error message.');
        }, 2000);
    };

    const closeErrorModal = () => {
        setShowErrorModal(false);
        setError('');
    };

    return (
        <div className='flex flex-col w-full max-w-full sm:max-w-[550px] mx-auto bg-[#303132] px-[20px] md:px-[40px]'>
            <div className='flex flex-col w-full'>
                <h1 className='text-[18px] md:text-[21px] mt-[20px] md:mt-[30px] font-[700] text-[#7190eb]'>
                    Protect your wallet with a password
                </h1>
                <p className='text-[#fffc] text-[13px] md:text-[14px] mb-[30px] md:mb-[40px] leading-[22px] md:leading-[24px]'>
                    The password you enter encrypts your private key and gives access to your funds. Please store your password in a safe place. We don't keep your information and can't restore it.
                </p>
            </div>
            <div className='flex items-center w-full rounded-[10px] py-[8px] md:py-[10px]'>
            <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='placeholder:text-[#a2a5a2] bg-transparent rounded-[5px] border border-[1px] border-[#292a2b] text-white text-[16px] w-full px-[15px] md:px-[20px] py-[8px] md:py-[10px] focus:border-[#7190eb] focus:outline-none'
                    placeholder='Password'
                />
            </div>
            <div className="flex items-center w-full rounded-[10px] py-[8px] md:py-[10px] relative">
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="placeholder:text-[#a2a5a2] text-white text-[16px] bg-transparent rounded-[5px] border border-[1px] border-[#292a2b] w-full px-[15px] md:px-[20px] py-[8px] md:py-[10px] pr-[50px] focus:border-[#7190eb] focus:outline-none"
                    placeholder="Confirm password"
                />
                <div className="absolute right-[15px] md:right-[20px] flex items-center gap-[5px]">
                    <img src="asset/lock.svg" alt="" className="w-[15px] h-[15px]" />
                    <span className="text-[#7190eb] text-[10px] md:text-[12px]">Secure encryption</span>
                </div>
            </div>

            {/* Back Button and Main Action Button */}
            <div className='flex justify-between items-center w-full mt-[15px] md:mt-[20px] mb-[30px] md:mb-[40px]'>
                <div className='flex items-center justify-center gap-[5px] cursor-pointer' onClick={() => navigate('/')}>
                    <img src='asset/arrbck.svg' alt='arrbck' className='w-[15px] h-[15px]' />
                    <span className='text-[#3365f2] text-[13px] md:text-[14px]'>Back</span>
                </div>

                <button
                    className='px-[30px] md:px-[50px] py-[10px] md:py-[13px] text-[14px] md:text-[15px] bg-[#7190eb] rounded-[3px] text-white hover:bg-[#6b87d9]'
                    onClick={handleButtonClick}
                    disabled={!password || !confirmPassword}
                >
                    {loading ? <div className="spinner text-[#23f7dd]"></div> : "I've written it down"}
                </button>
            </div>
            {showErrorModal && (
                <Err errorMessage={error} onClose={closeErrorModal} />
            )}
        </div>
    );
}
