import React, { useState, useEffect } from 'react';
import { QstMrk, NightMode, Home, DayMode } from '../Svg';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/Themecontext';

export default function Navbar() {

    const { isDarkMode, toggleTheme } = useTheme();


    const navigate = useNavigate();
    const Create = () => navigate('/create');
    const Restore = () => navigate('/restore');

   
    const items = [
        { label: "Create Wallet", onClick: () => Create() },
        { label: "Restore or Import", onClick: () => Restore() },
    ];

    return (
        <nav className={`flex justify-between w-full px-[70px] py-[10px] bg-[#303132] items-center`}>
            <div className="flex gap-[50px] items-center">
                <img src='asset/logo.svg' alt='logo' className='w-[50px] h-[40px] cursor-pointer' onClick={() => navigate('/')} />
                <div className="hidden md:flex gap-[25px] uppercase items-center text-white">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={`hover:border-b-[2px] text-white hover:border-[#7190eb] items-center text-[16px]`}
                            onClick={item.onClick}
                        >
                            <h1 className="cursor-pointer">{item.label}</h1>
                        </div>
                    ))}
                </div>
            </div>


            <div className="flex gap-[50px] items-center">
                <div className="flex items-center gap-[30px]">
                    <div className="w-[20px] h-[20px] cursor-pointer">
                        <a href='https://support.guarda.com/' target='_blank' rel="noopener noreferrer">
                            {QstMrk}
                        </a>
                    </div>

                    <div
                        className="w-[20px] h-[20px] cursor-pointer"
                        onClick={toggleTheme}
                    >
                        {NightMode}
                    </div>
                </div>
            </div>
        </nav>
    );
}
