import React from 'react';

export default function Err({ onClose }) {
    return (
        <div className='flex flex-col w-[400px] justify-center items-center mx-auto bg-[#303132] rounded-[5px] p-4'>
            <img src='asset/logo.svg' alt='logo' className='w-[60px] h-[60px] mb-4' />
            <div className='text-white text-center mb-4'>
                Oops! We couldn’t establish a connection to the Guarda blockchain network. Please ensure your internet connection is stable and try again.
            </div>
            <button
                onClick={onClose}
                className='bg-[#7190eb] text-white rounded-[5px] px-4 py-2'>
                Okay
            </button>
        </div>
    );
}
